import type { ComponentProps, ReactNode } from "react";
import { useState } from "react";
import { mcn } from "../utils/baseProps.ts";
import { cn } from "../utils/classNames.ts";
import { ClipBoardTooltip } from "./ClipBoardTooltip.tsx";
import { WTooltipButton } from "./button/WToolButton.tsx";

export function SecretKey({ value }: { value: string }) {
    const [isVisible, setIsVisible] = useState(false);

    function toggleVisibility() {
        setIsVisible(!isVisible);
    }

    return (
        <div className="inline-flex items-center gap-0.375em" data-testid="SecretKey">
            <ClipBoardTooltip text={value}>
                <SecretStack
                    secret={value}
                    placeholder={<StarText className="color-text cursor-pointer" />}
                    // placeholder={<CharBackground onClick={toggleVisibility} />}
                    isVisible={isVisible}
                />
            </ClipBoardTooltip>

            <WTooltipButton
                data-testid="ShowHideButton"
                title={isVisible ? "Hide" : "Show"}
                size="xs"
                color="primary"
                variant="ghost"
                action={toggleVisibility}
                icon={
                    isVisible
                        ? "jp-nopreview-icon size-0.875rem text-primary"
                        : "jp-preview-icon size-0.875rem text-primary"
                }
            />
        </div>
    );
}

/** Use CSS grid to stack two components on top of each other */
function SecretStack({
    secret,
    placeholder,
    isVisible,
}: {
    secret: ReactNode;
    placeholder: ReactNode;
    isVisible: boolean;
}) {
    const gridCell = "row-start-1 row-end-2 col-start-1 col-end-2";
    return (
        <div className="inline-grid mr-1">
            <div data-testid="SecretStackValue" className={cn(!isVisible ? "invisible" : "visible", gridCell)}>
                {secret}
            </div>
            <div data-testid="SecretKeyPlaceholder" className={cn(isVisible ? "invisible" : "visible", gridCell)}>
                {placeholder}
            </div>
        </div>
    );
}

function _CharBackground({ icon = "i-oui:asterisk", ...props }: { icon?: string } & ComponentProps<"div">) {
    return (
        <div
            className={`size-full ${icon} color-muted`}
            style={{
                maskRepeat: "space",
                maskSize: "0.9em",
                maskPosition: "center",
            }}
            {...props}
        />
    );
}

function StarText({ maxCharCount = 50, ...props }: { maxCharCount?: number } & ComponentProps<"div">) {
    const height = "var(--lh, 1.1em)";
    return (
        <div {...mcn("inline-block relative size-full", props)}>
            <div
                // Use absolute positioning so the asterisk text does not affect the layout in any way
                className="absolute inset-0 select-none"
                style={{
                    letterSpacing: "0.1em",

                    // Hack to achieve the same effect as `text-ellipsis: ""`
                    // by wrapping text to multiple lines, but hiding all but the first line.
                    overflow: "hidden",
                    // Constrain height to exactly the line height so any overflow is hidden
                    lineHeight: height,
                    height,
                    // Break in the middle of characters
                    overflowWrap: "anywhere",
                }}
            >
                {"*".repeat(maxCharCount)}
            </div>
        </div>
    );
}
