import { isDeepEqual } from "remeda";

export function changedFields<T extends Record<string, any>>(original: T, target: T) {
    let changed: Partial<T> | undefined = undefined;
    for (const key in target) {
        if (!isDeepEqual(original[key], target[key])) {
            changed ??= {};
            changed[key] = target[key];
        }
    }
    return changed;
}
