import type { ComponentType } from "react";
import { isTodoFn } from "../../dev/todoFn.ts";
import { DeleteButton } from "../button/DeleteButton.tsx";
import { UnAssignButton } from "../button/UnAssignButton.tsx";
import { WModal, WModalContent, type WModalContentProps, type WModalProps } from "./WModal.tsx";

interface ModalButtonProps {
    isDisabled?: boolean;
    inTable?: boolean;
    isTodo?: boolean;
    /** The button does not need an action since `DialogTrigger` will handle it */
    action: undefined;
}

export interface ActionModalProps
    extends Omit<WModalProps, "button" | "children">,
        WModalContentProps,
        Partial<ModalButtonProps> {
    buttonComponent: ComponentType<ModalButtonProps>;
}

export function ActionModal({
    buttonComponent: ButtonComponent,
    isDisabled,
    inTable,
    isTodo,
    children,
    ...modalContentProps
}: ActionModalProps) {
    const { modalAction } = modalContentProps;
    return (
        <WModal
            button={
                <ButtonComponent
                    isDisabled={isDisabled}
                    inTable={inTable}
                    isTodo={isTodo ?? isTodoFn(modalAction)}
                    action={undefined}
                />
            }
        >
            <WModalContent {...modalContentProps}>{children}</WModalContent>
        </WModal>
    );
}

export type SimpleModalProps = Omit<ActionModalProps, "buttonComponent" | "label">;

export function DeleteModal(props: SimpleModalProps) {
    return <ActionModal buttonComponent={DeleteButton} label="Delete" {...props} />;
}

export function UnAssignModal(props: SimpleModalProps) {
    return <ActionModal buttonComponent={UnAssignButton} label="Unassign" {...props} />;
}
