import React from "react";
import { WButton, type ButtonLookProps } from "../../components/button/WButton.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import type { EBillingAccount } from "./billingLogic.tsx";
import type { AddPaymentMethodModalProps } from "./choose_method/AddPaymentMethodModal.tsx";

const AddPaymentMethodModal = React.lazy(() => import("./choose_method/AddPaymentMethodModal.tsx"));
const BuyCreditModal = React.lazy(() => import("./topup/TopUpModal.tsx"));

export interface AccountButtonProps extends ButtonLookProps {
    account: EBillingAccount;
}

// TODO: Should the modal open out-of-context (ie. should it close when the parent component unmounts or the user navigates away)?
export function TopUpNowButton({ children, account, ...props }: AccountButtonProps) {
    return (
        <WModal
            button={
                <WButton
                    color="primary"
                    icon="jp-wallet-icon"
                    action={undefined}
                    isDisabled={!account.canTopUp}
                    {...props}
                >
                    {children ?? "Top Up Now"}
                </WButton>
            }
        >
            <BuyCreditModal account={account} />
        </WModal>
    );
}

interface AddPaymentMethodButtonProps extends AccountButtonProps, Pick<AddPaymentMethodModalProps, "onlyAutomated"> {}

export function AddPaymentMethodButton({ children, account, onlyAutomated, ...props }: AddPaymentMethodButtonProps) {
    return (
        <WModal
            button={
                <WButton color="primary" icon="jp-icon-add" action={undefined} {...props}>
                    {children ?? "Add Payment Method"}
                </WButton>
            }
        >
            <AddPaymentMethodModal billingAccount={account} onlyAutomated={onlyAutomated} />
        </WModal>
    );
}
