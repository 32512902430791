import { Suspense } from "react";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { googleTagManagerQueryAtom } from "./googleTagManagerQuery.ts";
import { UpdateGoogleTagManagerKeyModal } from "./UpdateGoogleTagManagerKeyModal.tsx";

function TrackingViewCustom() {
    const gtm_key = useSuspenseQueryAtom(googleTagManagerQueryAtom);

    return (
        <>
            <AdminTitle title="Tracking Codes" />

            <WTable adminTable>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td>Google Tag Manager key</td>
                        <td>
                            Add or edit your Google Tag Manager key to enable platform tracking. (Key format:
                            GTM-xxxxxxx)
                        </td>
                        <td>
                            <UpdateGoogleTagManagerKeyModal
                                value={gtm_key?.gtm_key}
                                button={<EditableButton action={undefined}>{gtm_key?.gtm_key}</EditableButton>}
                            />
                        </td>
                    </tr>
                </WTableBody>
            </WTable>
        </>
    );
}

export function TrackingView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <TrackingViewCustom />
        </Suspense>
    );
}
